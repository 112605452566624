import React from "react"
import PropTypes from "prop-types"
import Card from "./card"

function Partner({ name, title, logo, url, children }) {
    if (!title)
        title = name
        
    let link = null;
    let image = null;
    
    if (url)
        link = {
            title: 'Mehr über ' + name,
            url: url,
            external: true
        }
        
    if (logo)
        image = <img src={logo} alt="" />
    
    return (
        <Card title={title} link={link} image={image} classes={['card--inline']}>
            {children}
        </Card>
    )
}

Partner.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    logo: PropTypes.string,
    url: PropTypes.string
}

export default Partner
