import React from "react";
import Section from "../section";
import Column from "../column";
import TextBlock from "../text-block";
import Partner from "../partner";
import BrandName from "../brand-name";

import LogoArvato from "../../images/logo-arvato.svg";
import LogoEhex from "../../images/logo-ehealthexperts.svg";
import LogoSecunet from "../../images/logo-secunet.svg";

export default ({ classes, id, text, children }) => (
    <Section classes={classes} id={id ? id : "partners"}>
        <div className="container">
            <Column classes={["intro"]}>
                <TextBlock title="{b}Drei Partner,{/b} ein Zugang.">
                    {text && (
                        <p>
                            <strong>Seit 2013</strong> gehört der Aufbau und
                            Betrieb der zentralen Telematikinfrastruktur für das
                            deutsche Gesundheitssystem zu unseren Aufgaben.
                            Unser Netzwerk aus drei starken Partnern hat sich
                            auf den physischen Anschluss der Praxen durch
                            geschulte DVO spezialisiert. Mit <BrandName />{" "}
                            erhalten Sie den Zugang zur Telematikinfrastruktur{" "}
                            <strong>aus der Hand des Betreibers.</strong>
                        </p>
                    )}
                </TextBlock>
            </Column>
        </div>
        <div className="container">
            <div className="cards">
                <Column classes={["cards__column"]}>
                    <Partner
                        name="Arvato Systems"
                        title="Infrastruktur-Profi"
                        url="http://www.arvato-systems.de/mein-zugangsdienst"
                        logo={LogoArvato}
                    >
                        <p>
                            Arvato Systems unterstützt als international
                            agierender IT-Spezialist namhafte Unternehmen bei
                            der digitalen Transformation.
                        </p>
                    </Partner>
                </Column>
                <Column classes={["cards__column"]}>
                    <Partner
                        name="ehex"
                        title="eHealth-Profi"
                        url="https://www.ehealthexperts.de"
                        logo={LogoEhex}
                    >
                        <p>
                            ehex ist ein agiles Unternehmen des
                            Gesundheitswesens und seit vielen Jahren als Kenner
                            und Taktgeber zu allen Themen rund um die
                            Telematikinfrastruktur präsent.
                        </p>
                    </Partner>
                </Column>
                <Column classes={["cards__column"]}>
                    <Partner
                        name="secunet"
                        title="Sicherheits-Profi"
                        url="https://www.secunet.com/de/"
                        logo={LogoSecunet}
                    >
                        <p>
                            secunet ist langjähriger IT-Sicherheitspartner der
                            Bundesrepublik Deutschland und stellt die Sicherheit
                            und Vertraulichkeit von Patientendaten sicher.
                        </p>
                    </Partner>
                </Column>
            </div>
        </div>
    </Section>
);
